import React from "react";
import useSWR from "swr";
import fetcher from "../lib/fetcher";

function Location() {
  const { data } = useSWR("/api/geo", fetcher);

  const country = data?.headers_country;
  const region = data?.headers_region;
  const city = data?.headers_city;

  return (
    <>
      <p>
        The : {country} : {region} : {city} : Edition
      </p>
    </>
  );
}

export default Location;
