import Head from "next/head";
import styles from "../styles/Home.module.css";
import Location from "../components/location";

export default () => (
  <>
    <Head>
      <link rel="icon" href="/favicon.ico" />
      <link
        href="https://fonts.googleapis.com/css?family=Playfair+Display:400,700,900,400italic,700italic,900italic|Droid+Serif:400,700,400italic,700italic"
        rel="stylesheet"
        type="text/css"
      />
      <title>The Next Times : a news & media demo of Nextjs + Vercel</title>

      <link rel="stylesheet" href="styles/styles.css"></link>
    </Head>

    <div class="head" className={styles.main}>
      <div class="headerobjectswrapper">
        <div class="weatherforcastbox">
          <span>
            Weatherforcast for the region : IP lookup API example ___ next 24
            hours: Plenty of Sunshine
          </span>
          <br />
          <span>Wind: 7km/h SSE; Ther: 21°C; Hum: 82%</span>
          <br />
        </div>
        <header>The Next Times</header>
      </div>

      <div class="subhead">
        <Location />
      </div>
    </div>
    <div class="content">
      <div class="columns">
        <div class="column">
          <div class="head">
            <span class="headline hl3">Heading 1</span>
            <p>
              <span class="headline hl4">by Stefan</span>
            </p>
          </div>
          <p>
            Lorem ipsum dolor; sit amet consectetur adipisicing elit. Qui aut,
            laudantium omnis velit vitae culpa dignissimos consectetur
            exercitationem quae illo minima maiores quaerat ullam sit tenetur
            veritatis enim consequuntur nemo?
          </p>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Veniam
            repudiandae ea quidem exercitationem est accusantium consequatur
            amet, dolorem nihil ullam dolorum esse? Cupiditate quas velit fugiat
            iusto? Nam, incidunt provident. Lorem ipsum dolor sit, amet
            consectetur adipisicing elit. Repellat corporis esse odit libero
            vero. Exercitationem magnam doloribus corporis optio maiores
            sapiente modi, dignissimos blanditiis quidem neque dolores atque rem
            culpa!
          </p>
          <button type="button" onClick={() => {
            throw new Error("Sentry Frontend Error");
          }}>
    Throw error
</button>
        </div>
        <div class="column">
          <div class="head">
            <span class="headline hl5">Give people choice</span>
            <p>
              <span class="headline hl6">The foundations of it all</span>
            </p>
          </div>
          <p>
            Lorem, ipsum dolor sit amet consectetur adipisicing elit.
            Reprehenderit qui ea laborum? Labore tempora sequi nesciunt
            repudiandae error? Facilis sapiente, inventore fuga voluptas sunt
            provident illum mollitia modi corrupti asperiores.
          </p>
        </div>
        <div class="column">
          <div class="head">
            <span class="headline hl1">May the Fifth be with you</span>
            <p>
              <span class="headline hl2">
                Let go your mind go, and your body will follow
              </span>
            </p>
          </div>
          <p>
            Lorem, ipsum dolor sit amet consectetur adipisicing elit.
            Reprehenderit qui ea laborum? Labore tempora sequi nesciunt
            repudiandae error? Facilis sapiente, inventore fuga voluptas sunt
            provident illum mollitia modi corrupti asperiores.
          </p>
          <p>
            <span class="citation">"Don't under&shy;estimate the Farce. "</span>
          </p>
        </div>
        <div class="column">
          <div class="head">
            <span class="headline hl3">Story 4</span>
            <p>
              <span class="headline hl4">Speed, purpose and flexibility</span>
            </p>
          </div>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugiat
            impedit provident aliquid, molestiae cupiditate, veritatis
            consequuntur quod quam quasi quidem excepturi? Facere soluta id
            repudiandae harum ullam sit possimus aspernatur!
          </p>
        </div>
        <div class="column">
          <div class="head">
            <span class="headline hl1">It wasn't a dream </span>
            <p>
              <span class="headline hl4">by FRANZ KAFKA</span>
            </p>
          </div>
          <p>
            Lorem, ipsum dolor sit amet consectetur adipisicing elit.
            Reprehenderit qui ea laborum? Labore tempora sequi nesciunt
            repudiandae error? Facilis sapiente, inventore fuga voluptas sunt
            provident illum mollitia modi corrupti asperiores.
          </p>
        </div>
      </div>
    </div>
  </>
);
